// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-js": () => import("./../../../src/pages/legacy.js" /* webpackChunkName: "component---src-pages-legacy-js" */),
  "component---src-pages-submissions-js": () => import("./../../../src/pages/submissions.js" /* webpackChunkName: "component---src-pages-submissions-js" */),
  "component---src-pages-trustees-js": () => import("./../../../src/pages/trustees.js" /* webpackChunkName: "component---src-pages-trustees-js" */)
}

